/**
 * Main Javascript
 */

function ready() {

    // Get document language
    const lang = document.documentElement.lang;

    // Form translations
    const formTranslations = {
        'nom':       'Name',
        'courriel':  'Email',
        'telephone': 'Phone',
        'nombre':    'Number of persons',
        'heure':     'Time',
        'objet':     'Object of the request',
        'questions': 'Questions / Comments',
        'sujet':     'Subject'
    };

    // This function translate the various form labels
    const translateForm = translations => {
        for (const [key, value] of Object.entries(translations)) {
            let formElement = document.getElementsByName(key);
            if (formElement.length) {
                formElement[0].placeholder = value;
            }
        }
    }

    // Call translation fuction on english site
    if (lang == 'en') {
        translateForm(formTranslations);
    }
    
    // This function set the content offset top
    const setContentOffset = (header, content) => {
        content.style.top = header.offsetHeight + 'px';
    };

    let fixedWrap  = document.getElementById('fixed-wrap');
    let scrollWrap = document.getElementById('scroll-wrap');
    let viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    if (fixedWrap && scrollWrap && (viewportWidth > 1200)) {
        setContentOffset(fixedWrap, scrollWrap);

        // Fix header height on window resize
        window.onresize = function(event) {
            let newFixedWrap  = document.getElementById('fixed-wrap');
            let newScrollWrap = document.getElementById('scroll-wrap');
            setContentOffset(newFixedWrap, newScrollWrap);
        }
        
        // Fade out header elements
        window.onscroll = function(event) {

            let sliderContainer = document.querySelector('.header--homepage > .header-slideshow');
            let primaryNavigation = document.querySelector('.header--homepage .primary-navigation');
            let headerLogo = document.querySelector('.header--homepage .header-logo');
            
            primaryNavigation.style.opacity = 1 - document.documentElement.scrollTop / 250;
            sliderContainer.style.opacity = 1 - document.documentElement.scrollTop / 750;
            headerLogo.style.opacity = 1 - document.documentElement.scrollTop / 1250;
        }
    }    
}

// Initial page load
document.addEventListener('DOMContentLoaded', ready);